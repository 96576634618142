header {
    display: flex;
    margin-bottom: 1.5rem;

    img {
        width: 250px;
        height: auto;
    }
}


html {
    position: relative;
    min-height: 100%;

    body {
        margin: 0 0 75px;
        padding: 10px;
    }
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    overflow: hidden;
    background-color: #1b1b1b;
    color: white;

    .center {
        height: 75px;
        line-height: 75px;
        text-align: center;
    }
}

label .btn-minus {
    border: none;
    .icon-minus {
        color: red;
    }
}

.save-size {
    height: 50px;
}

.code-snippet-block {
    background-color: transparent;
    color: #0083cb;
}

.accordion input:checked~.accordion-body, .accordion[open] .accordion-body {
    &.p-1 {
        max-height: 100%;
    }
}

.rendering-pdf-screen {
    text-align: center;
    cursor: progress;
}

.important-to-me-radio {
    label {
        text-align: center;
        padding: 0.1rem 0.8rem 0.1rem 1.2rem;
    }
}