.fullsize {
    width: 100%;
    height: 100%;
}

.save-size {
    height: 50px;
}

.important-subtitle {
    margin: 0;
    font-size: smaller;
}

label.padding-radiobtnheader {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    margin-right: 0.5px;
    padding: 0.1rem 0.8rem 0px 0.8rem !important;
    span {
        display: inline-block;
        position: absolute;
        top: 0.3rem;
        height: 0.8rem;
        width: 0.8rem;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}

label.padding-radiobtn {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    margin-right: 0.5px;
    padding: 0.1rem 0.8rem 0.1rem 0.8rem;
    i {
        height: 0.8rem;
        width: 0.8rem;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}